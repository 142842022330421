<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :channel="channel"
      :locale="language"
      :translations-overrides="translations"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails'
import { EEValidator } from '@bigbank/dc-common/validators/ee/EEValidator.class'
import { useAppStore } from '@/store/app.store'

const eePhoneValidator = require('@bigbank/dc-common/validators/ee/phoneNumber')

const eeValidator = new EEValidator()

export default {
  name: 'personal-and-company-details-ee-legal',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+372',
      form: {
        personalIdentityCode: undefined,
        companyName: undefined,
        companyRegistrationCode: undefined,
        bankAccountNumber: undefined,
        firstName: undefined,
        surname: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        externalRegistryCheckConsent: null,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: true,
      fieldDefinitions: [
        { name: 'companyName' },
        { name: 'companyRegistrationCode' },
        {
          name: 'bankAccountNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid IBAN account number'),
            validate: value => eeValidator.isValidIBAN(value, true)
          }
        },
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => eeValidator.isValidPersonalId(value)
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid phone number'),
            validate: value => eePhoneValidator(value, this.channel)
          }
        },
        { name: 'marketingConsent' },
        { name: 'externalRegistryCheckSilentConsent' },
        { name: 'dataProcessingSilentConsent' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language']),
    translations () {
      return {
        header: this.$pgettext('registration_legal', 'Personal and company details'),
        subtitle: this.$pgettext('registration_legal', 'Please fill this form below to open new deposit for the company you represent')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
