<template>
  <bb-application-page
    :form-props="applicationPageProps"
    @submit="onSubmit"
  >
    <bb-public-header
      slot="header"
      :title="translations.header"
      icon="client-contact"
    />
    <p v-if="errorMessage" class="f-color-red align-center f-gotham f-small m-t-10 m-b-20">
      {{errorMessage}}
    </p>
    <bb-input
      v-model="form.street"
      v-validate="'required'"
      :label="translations.street"
      :data-vv-as="translations.street"
      name="street"
    />
    <bb-input
      v-model="form.city"
      v-validate="'required'"
      :label="translations.city"
      :data-vv-as="translations.city"
      name="city"
    />
    <bb-input
      v-model="form.postalCode"
      v-validate="'required|cb_postal_code'"
      :label="translations.postalCode"
      :data-vv-as="translations.postalCode"
      name="postalCode"
    />
    <bb-input
      v-model="countryToDisplay"
      v-validate="'required'"
      :label="translations.country"
      :data-vv-as="translations.country"
      :disabled="true"
      :helpActive="true"
      :helpText="translations.countryHelp"
      name="country"
    />
  </bb-application-page>
</template>

<script>
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput
} from '@bigbank/interface-components'
import '@bigbank/interface-components/dist/svg/client/contact'
import * as crossBorderPostalCodeValidator from '@bigbank/dc-common/validators/cb/postalCode'
import RegistrationMixin from '../common/RegistrationMixin'
import errorHandler from './../../errors/errorHandler'

import { mapActions, mapState } from 'pinia'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'registration-address-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      countryToDisplay: '',
      form: {
        street: '',
        city: '',
        postalCode: '',
        country: ''
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['featureFlags', 'channel', 'formData', 'countries']),
    translations () {
      return {
        header: this.$pgettext('registration', 'Registration Address Details'),
        street: this.$pgettext('registration', 'Street'),
        city: this.$pgettext('registration', 'City'),
        postalCode: this.$pgettext('registration', 'Postal Code'),
        country: this.$pgettext('registration', 'Country'),
        countryHelp: this.countryHelpText,
        errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE'),
        submitText: this.$pgettext('registration', 'Continue'),
        invalidPostalCode: this.$pgettext('registration', 'Please enter valid postal code'),
        errors: {
          INTERNAL_ERROR: this.$pgettext('registration_error', 'Something went wrong, please try again later. If the error persists, please contact as an provide the following error code: #CODE#.'),
          VALIDATION_FAILED: this.$pgettext('registration_error', 'Form validation failed, please double check your data and try again.')
        }
      }
    },
    countriesByChannel () {
      return this.countries.find(country => country.countryCode === this.channel) || {}
    },
    formattedPostalCode () {
      let formattedPostalCode = this.form.postalCode.toUpperCase()
      if (this.channel === 'NL') {
        formattedPostalCode = formattedPostalCode.replace(/\s*/g, '')
      }

      return formattedPostalCode
    },
    applicationPageProps () {
      return {
        uppercaseButtons: false,
        errorText: this.translations.errorText,
        submitText: this.translations.submitText,
        disabled: this.isLoading,
        loading: this.isLoading
      }
    },
    countryHelpText () {
      switch (this.channel.toUpperCase()) {
        case CountryChannel.AT:
          return this.$pgettext('registration', 'This is the explanation why it is fixed [this text is for Austria only]')
        default:
          return this.$pgettext('registration', 'This is the explanation why it is fixed')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep', 'getCountries', 'getStatus']),
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        this.form.postalCode = this.formattedPostalCode

        await this.updateFormData({ registrationAddressDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    init () {
      this.form = {
        ...this.form,
        country: this.countriesByChannel.countryCode.toUpperCase()
      }

      this.countryToDisplay = this.countriesByChannel.translation
    }
  },
  async created () {
    this.$validator.extend('cb_postal_code', {
      getMessage: () => this.translations.invalidPostalCode,
      validate: () => crossBorderPostalCodeValidator(this.channel, this.formattedPostalCode)
    })

    try {
      await this.getCountries()
      this.init()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
