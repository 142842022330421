<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: false,
      errorText: translations.errors.errorText,
      submitText: translations.submitText,
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="onSubmit"
  >
    <bb-public-header
      slot="header"
      :title="translations.header"
      icon="client-personal"
    />
    <p v-if="errorMessage" class="f-color-red align-center f-gotham f-small m-t-10 m-b-20">
      {{errorMessage}}
    </p>
    <bb-input
      v-model="form.registrationAddress.streetAddress"
      v-validate="'required'"
      :label="translations.streetAddress"
      :data-vv-as="translations.streetAddress"
      name="registrationStreetAddress"
      @change="onAddressDetailsChange('registrationAddress')"
    />
    <bb-input
        v-model="form.registrationAddress.houseNumber"
        :label="translations.houseNumber"
        :data-vv-as="translations.houseNumber"
        name="registrationHouseNumber"
        @change="onAddressDetailsChange('registrationAddress')"
      />
      <bb-input
        v-model="form.registrationAddress.flatNumber"
        :label="translations.flatNumber"
        :data-vv-as="translations.flatNumber"
        name="registrationFlatNumber"
      />
    <bb-input
      v-model="form.registrationAddress.city"
      v-validate="'required'"
      :label="translations.city"
      :data-vv-as="translations.city"
      name="registrationCity"
      @change="onAddressDetailsChange('registrationAddress')"
    />
    <bb-input
      v-model="form.registrationAddress.county"
      v-validate="'required'"
      :label="translations.contactCounty"
      :data-vv-as="translations.contactCounty"
      name="registrationCounty"
    />
    <bb-masked-input
      v-model="form.registrationAddress.postcode"
      v-validate="'required|lt_postal_code'"
      mask="LT-11111"
      :label="translations.postalCode"
      :data-vv-as="translations.postalCode"
      name="registrationPostalCode"
    />
    <bb-select
      readonly
      v-model="form.registrationAddress.country"
      v-validate="'required'"
      :options="countryOptionsWithExcludedStateless"
      :label="translations.country"
      :data-vv-as="translations.country"
      :disabled="true"
      :helpText="translations.countryTooltip"
      :helpActive="true"
      name="registrationCountry"
    />
    <bb-checkbox
      :checked="isSameAddress"
      @change="onSameContractAddressCheckboxChanged"
      name="registrationSameAsContactAddress"
      :label="translations.isSameAddress"
      v-model="isSameAddress"
      data-vv-validate-on="change"
    >{{ translations.sameAddress }}</bb-checkbox>
    <template v-if="!isSameAddress">
      <bb-input
        v-model="form.contactAddress.streetAddress"
        v-validate="'required'"
        :label="translations.contactStreetAddress"
        :data-vv-as="translations.contactStreetAddress"
        name="contactStreetAddress"
        @change="onAddressDetailsChange('contactAddress')"
      />
      <bb-input
        v-model="form.contactAddress.houseNumber"
        :label="translations.contactHouseNumber"
        :data-vv-as="translations.contactHouseNumber"
        name="contactHouseNumber"
        @change="onAddressDetailsChange('contactAddress')"
      />
      <bb-input
        v-model="form.contactAddress.flatNumber"
        :label="translations.contactFlatNumber"
        :data-vv-as="translations.contactFlatNumber"
        name="contactFlatNumber"
      />
      <bb-input
        v-model="form.contactAddress.city"
        v-validate="'required'"
        :label="translations.contactCity"
        :data-vv-as="translations.contactCity"
        name="contactCity"
        @change="onAddressDetailsChange('contactAddress')"
      />
      <bb-input
        v-model="form.contactAddress.county"
        v-validate="'required'"
        :label="translations.contactCounty"
        :data-vv-as="translations.contactCounty"
        name="contactCounty"
      />
      <bb-input
        :value="form.contactAddress.postcode"
        @input="handleContactPostcodeChange"
        v-validate="'required|lt_contact_postal_code'"
        :label="translations.contactPostalCode"
        :data-vv-as="translations.contactPostalCode"
        name="contactPostalCode"
      />
      <bb-select
        v-model="form.contactAddress.country"
        v-validate="'required'"
        :options="countryOptionsWithExcludedStateless"
        :label="translations.contactCountry"
        :data-vv-as="translations.contanctCountry"
        @change="onContactCountryChange"
        name="contactCountry"
      />
    </template>
  </bb-application-page>
</template>

<script>
import RegistrationMixin from '../../common/RegistrationMixin'
import { cleanInput } from '../../common/util'
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbCheckbox,
  BbSelect,
  BbMaskedInput
} from '@bigbank/interface-components'
import { mapActions, mapState } from 'pinia'
import errorHandler from '../../../errors/errorHandler'
import { STATELESS_COUNTRY_CODE } from '@/const'
import { LTValidator } from '@bigbank/dc-common/validators/lt/LTValidator.class'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'
import { useLithuaniaStore } from '@/store/lithuania.store'

const ltValidator = new LTValidator()

export default {
  name: 'address-details-lt-legal',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbCheckbox,
    BbSelect,
    BbMaskedInput
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      isSameAddress: true,
      form: {
        registrationAddress: {
          streetAddress: undefined,
          city: undefined,
          flatNumber: undefined,
          houseNumber: undefined,
          postcode: undefined,
          county: undefined,
          country: 'LT'
        },
        contactAddress: {
          streetAddress: undefined,
          city: undefined,
          flatNumber: undefined,
          houseNumber: undefined,
          postcode: undefined,
          county: undefined,
          country: 'LT'
        }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['formData', 'countries']),
    translations () {
      return {
        header: this.$pgettext('registration', 'Address of representative'),
        streetAddress: this.$pgettext('registration', 'Street & house'),
        city: this.$pgettext('registration', 'City'),
        postalCode: this.$pgettext('registration', 'Postal code'),
        houseNumber: this.$pgettext('registration', 'Street number'),
        flatNumber: this.$pgettext('registration', 'Apartment/house number'),
        country: this.$pgettext('registration', 'Country'),
        contactCounty: this.$pgettext('registration', 'County'),
        contactHouseNumber: this.$pgettext('registration', 'Street number'),
        contactFlatNumber: this.$pgettext('registration', 'Apartment/house number'),
        contactStreetAddress: this.$pgettext('registration', 'Mailing Street & house'),
        contactCity: this.$pgettext('registration', 'Mailing City'),
        contactPostalCode: this.$pgettext('registration', 'Mailing Postal code'),
        contactCountry: this.$pgettext('registration', 'Mailing Country'),
        countryTooltip: this.$pgettext('registration_lt', 'COUNTRY_HELP_TEXT'),
        sameAddress: this.$pgettext('registration', 'My postal address is the same as the registered address.'),
        submitText: this.$pgettext('registration', 'SUBMIT_FORM_BUTTON_TEXT'),
        errors: {
          invalidPostalCode: this.$pgettext('registration', 'Postal code has invalid format'),
          errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE')
        }
      }
    },
    countryOptionsWithExcludedStateless () {
      return this.getCountriesList([STATELESS_COUNTRY_CODE])
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep', 'getCountries']),
    ...mapActions(useLithuaniaStore, ['getPostcode']),
    getCountriesList (excludeByCode) {
      let countries = this.countries.map(country => ({
        value: country.countryCode,
        text: country.translation
      }))
        .sort((a, b) => a.text.localeCompare(b.text, this.locale))

      if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value))
      }

      return countries
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        if (this.isSameAddress) {
          this.form.contactAddress = { ...this.form.registrationAddress }
        }
        await this.updateFormData({ addressDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    async onAddressDetailsChange (addressType) {
      try {
        const form = this.form[addressType]
        if (form.streetAddress && form.houseNumber && form.city && form.country === CountryChannel.LT) {
          this.isLoading = true
          await this.updateFormData({ addressDetails: this.form })
          await this.getPostcode({
            customerType: 'legal',
            addressType
          })
        }
      } catch (err) {
        console.log(err)
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    handleContactPostcodeChange (value) {
      this.form.contactAddress.postcode = cleanInput(value)
    },
    onContactCountryChange () {
      this.$validator.validate('contactPostalCode')
    },
    onSameContractAddressCheckboxChanged (value) {
      if (!value) {
        setTimeout(() => {
          if ((this.form.contactAddress.streetAddress || '').trim().length > 0) {
            this.$validator.validate('contactStreetAddress')
          }
          if ((this.form.contactAddress.postcode || '').trim().length > 0) {
            this.$validator.validate('contactPostalCode')
          }
          if ((this.form.contactAddress.country || '').trim().length > 0) {
            this.$validator.validate('contactCountry')
          }
          if ((this.form.contactAddress.city || '').trim().length > 0) {
            this.$validator.validate('contactCity')
          }
        }, 100) // Delay to avoid errors
      }
    }
  },
  async created () {
    try {
      this.$validator.extend('lt_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => ltValidator.isValidPostalCode(value)
      })
      this.$validator.extend('lt_contact_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => {
          if (this.form.contactAddress.country !== 'LT') {
            return this.form.contactAddress.postcode.length >= 3
          }

          return ltValidator.isValidPostalCode(value)
        }
      })

      await this.getCountries()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
