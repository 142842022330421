<template>
  <common-layout
    :hideLoginButton="true"
  >
    <router-view />
  </common-layout>
</template>

<script>
import { mapState } from 'pinia'
import RoutingMixin from '../common/RoutingMixin'
import CommonLayout from '../../components/CommonLayout'
import { useAppStore } from '@/store/app.store'

export default {
  mixins: [
    RoutingMixin
  ],
  components: {
    CommonLayout
  },
  computed: {
    ...mapState(useAppStore, ['currentStep'])
  }
}
</script>
