<template>
  <personal-details
    :field-definitions="fieldDefinitions"
    :phone-number-prefix="phoneNumberPrefix"
    :channel="channel"
    :locale="language"
    :update-form-data="updateFormData"
    :go-to-next-step="goToNextStep"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails'
import * as idNumberValidator from '@bigbank/dc-common/validators/fi/idNumber'
import * as phoneNumberValidator from '@bigbank/dc-common/validators/fi/phoneNumber'
import { cleanInput } from '@/views/common/util'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'personal-details-fi',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+358',
      form: {
        personalIdentityCode: undefined,
        firstName: undefined,
        surname: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      fieldDefinitions: [
        {
          name: 'firstName',
          ifPrefilledThenDisabled: true
        },
        {
          name: 'surname',
          ifPrefilledThenDisabled: true
        },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => idNumberValidator(value)
          },
          ifPrefilledThenDisabled: true
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid phone number'),
            validate: value => {
              try {
                return phoneNumberValidator(cleanInput(value), this.channel)
              } catch (err) {
                return false
              }
            }
          }
        },
        {
          name: 'citizenship',
          disablePrefill: true,
          ifPrefilledThenDisabled: true
        },
        { name: 'formConfirmationText' },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' },
        { name: 'selfserviceTermsConfirmation' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language'])
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
