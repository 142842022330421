<template>
  <smart-id-signing
      visible
      :start-signing="startSigning"
      :complete-signing="completeSigning"
      v-on="$listeners"
  ></smart-id-signing>
</template>

<script>

import SmartIdSigning from '@/components/signing/SmartId'
import api from '@/api'

export default {
  name: 'sign-with-smart-id-lv',
  components: { SmartIdSigning },
  props: {
    signingRequestId: {
      required: true
    }
  },
  methods: {
    startSigning () {
      return api.startSmartIdSigning(this.signingRequestId)
    },
    completeSigning () {
      return api.completeSmartIdSigning(this.signingRequestId)
    }
  }
}
</script>
