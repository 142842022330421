<template>
  <thank-you-wrapper>
    <bb-public-header
      class="thank-you__header"
      slot="title"
      :title="title"
      icon="client-identification"
    ></bb-public-header>
    <template slot="text">
      <template v-for="(textObject, index) in activeTexts">
        <div :key="index">
          <template v-if="textObject.text">
            <text-with-links
                :text="textObject.text"
                class="thank-you__paragraph"
                :links="[]"
            />
          </template>
          <template v-if="textObject.list">
            <p class="thank-you__paragraph">
              <template v-for="(listText, listIndex) in textObject.list">
                <bb-list-item
                    :title="listText"
                    :titleUppercase="false"
                    bullet
                    :key="listIndex"
                    class="thank-you__list-item"
                ></bb-list-item>
              </template>
            </p>
          </template>
          <template v-if="textObject.appsSectionDownload">
            <div class="thank-you__download-app">
              <download-app
                :lang="language"
                :google-play-store-link="googlePlayStoreLink"
                :apple-app-store-link="appleAppStoreLink"
              />
            </div>
          </template>
        </div>
      </template>
    </template>
    <template v-if="showEvrotrustLogo" slot="poweredBy">
      <div class="powered-by__text">{{ translations.poweredByText }}</div>
      <div class="powered-by__logo">
        <bb-logo :logo="evrotrustLogo" alt="" fluid></bb-logo>
      </div>
    </template>
    <template v-if="hasAlertTexts" slot="alert">
      <bb-box ref="alerts" class="alert" :class="{ alert__many: hasManyAlertTexts }">
        <bb-icon size="24" color="#FFCC00" name="ui-alert-circle-outline" />
        <div v-if="hasManyAlertTexts">
          <template v-for="(textObject, index) in alertTexts">
            <template v-if="textObject.text">
              <p :key="index" class="alert__paragraph">
                {{ textObject.text }}
              </p>
            </template>
          </template>
        </div>
        <p v-else class="text-center color-gray-90 f-smallest">
          {{ alertTexts[0].text }}
        </p>
      </bb-box>
    </template>
    <template v-if="isContinueButtonShown" slot="button">
      <bb-sticky class="hidden-desktop" bleedMobileOnly>
        <bb-button
          display="blockMobileOnly"
          :to="continueLink"
          :label="translations.continueButtonText"
        />
      </bb-sticky>
      <div class="hidden-mobile">
        <bb-button
          display="block"
          :to="continueLink"
          :label="translations.continueButtonText"
          class="log-in-button-desktop"
        />
      </div>
    </template>
    <template v-else-if="refreshButton" slot="button">
      <div class="relative text-center">
        <bb-sticky bleedMobileOnly>
          <bb-button
            id="bg-thank-you-refresh-btn"
            corner="sharpMobileOnly"
            display="blockMobileOnly"
            v-bind="refreshButton"
            @click="onRefresh"
          />
        </bb-sticky>
      </div>
    </template>
  </thank-you-wrapper>
</template>

<script>
import { BbLogo, BbIcon, BbListItem, BbButton, BbSticky, BbBox, BbPublicHeader } from '@bigbank/interface-components'
import { isMobile } from '@bigbank/dc-common/util'
import ThankYouWrapper from '../../components/ThankYouWrapper'
import DownloadApp from '../../components/DownloadApp'
import TextWithLinks from './../../components/TextWithLinks'
import evrotrustLogo from '../../images/evrotrust-logo.png'
import { mapActions, mapState } from 'pinia'

import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'
import '@bigbank/interface-components/dist/svg/client/identification'
import { useAppStore } from '@/store/app.store'

const steps = {
  CHECK_IDENTIFICATION_STATUS: 'CHECK_IDENTIFICATION_STATUS',
  GET_IDENTIFICATION_DATA: 'GET_IDENTIFICATION_DATA'
}

export default {
  name: 'bg-thank-you',
  components: {
    BbLogo,
    BbIcon,
    BbListItem,
    BbButton,
    BbSticky,
    ThankYouWrapper,
    DownloadApp,
    TextWithLinks,
    BbBox,
    BbPublicHeader
  },
  data () {
    return {
      refreshLoading: false,
      evrotrustLogo,
      isUserSentToTheEvrotrustApp: false
    }
  },
  watch: {
    alertTexts: {
      immediate: true,
      handler (_, newValue) {
        if (!newValue || newValue.length <= 0) {
          return
        }
        this.scrollToElement('alerts')
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['status', 'customerCitizenCountryCode', 'language', 'channel', 'channelConfig', 'constants']),
    translations () {
      return {
        newCustomerTitle: this.$pgettext('thank-you', 'Let\'s get to know each other'),
        welcomeBackTitle: this.$pgettext('thank-you', 'Welcome back'),
        notBulgarianTitle: this.$pgettext('thank-you', 'Thank you for your interest!'),
        poweredByText: this.$pgettext('thank-you', 'Identification powered by'),
        continueButtonText: this.$pgettext('thank-you', 'Continue with application'),
        identifiedCustomerText: this.$pgettext('thank-you',
          'Dear customer,\n' +
          '{paragraph}\n' +
          'Thanks for choosing Bigbank for your financial matters!\n' +
          '{paragraph}\n' +
          'To continue with the process, we need to know, who you are. ' +
          'Please check your mobile phone for a notification from Evrotrust app. ' +
          'After you have approved sending us your personal details, we can direct you to your personal offer.\n' +
          '{paragraph}\n' +
          'If you need any assistance, please contact our customer service.'),
        notValidatedCustomerText: this.$pgettext('thank-you',
          'Dear customer,\n' +
          '{paragraph}\n' +
          'Thanks for choosing Bigbank for your financial matters!\n' +
          '{paragraph}\n' +
          'To continue with the process, we need to know, who you are. For this we use the help from Evrotrust.\n' +
          '{paragraph}\n' +
          'It seems, that you have Evrotrust app in your mobile phone, but you have not completed the identification in the app. ' +
          'Please open the app and check, what data is missing. If you open settings and your profile, ' +
          'you should see red notification, if something is missing.\n' +
          '{paragraph}\n' +
          'After successful registration, Evrotrust will ask your confirmation to send us your personal details. ' +
          'When you have approved sending us your data, we are happy to direct you to your personal offer.\n' +
          '{paragraph}\n' +
          'If you need any assistance, please contact our customer service.'
        ),
        notRegisteredCustomerText: this.$pgettext('thank-you',
          'Dear customer,\n' +
          '{paragraph}\n' +
          'Thanks for choosing Bigbank for your financial matters!\n' +
          '{paragraph}\n' +
          'To continue with the loan, we need to know, who you are. For this we use the help from Evrotrust.\n' +
          '{paragraph}\n' +
          'Please download Evrotrust app in your personal mobile phone:\n' +
          '{apps_download_section}' +
          '{paragraph}\n' +
          'Open the app and register yourself as Evrotrust user in four easy steps:\n' +
          '{list_item}Choose your PIN for security.\n' +
          '{list_item}Choose and insert control questions for extra security.\n' +
          '{list_item}Identify yourself with your ID card or passport.\n' +
          '{list_item}Identify yourself with your face to your phone\'s camera.\n' +
          '{paragraph}\n' +
          'After successful registration, Evrotrust will ask your confirmation to send us your personal details. ' +
          'When you have approved sending us your data, we are happy to direct you to your personal offer.\n' +
          '{paragraph}\n' +
          'If you need any assistance, please contact our customer service.'
        ),
        registeredCustomerText: this.$pgettext('thank-you',
          'Dear customer,\n' +
          '{paragraph}\n' +
          'Thanks for choosing Bigbank for your financial matters!\n' +
          '{paragraph}\n' +
          'To receive a personal offer, you need to log in to our self-service.\n' +
          '{paragraph}\n' +
          'If you need any assistance, please contact our customer service.'
        ),
        amlScreeningCheckFailedText: this.$pgettext('thank-you',
          'Thank you for applying, but we can\'t offer you deposit at this time.\n' +
          '{paragraph}\n' +
          'Customer support will contact you.'
        ),
        notBulgarianCustomerText: this.$pgettext('thank-you',
          'Sorry, we accept applications only from Bulgarian citizens.\n' +
          '{paragraph}\n' +
          'If you need any assistance, please contact our customer service.'
        ),
        identificationStatusAlerts: {
          CLIENT_NEEDS_TO_REIDENTIFY: this.$pgettext('identification_status_alert_CLIENT_NEEDS_TO_REIDENTIFY', 'Registration cannot be completed at the moment due to expired identification. Please check your Evrotrust app for further information and restart the registration once you\'ve successfully been identified in Evrotrust app.'),
          CLIENT_REJECTED_BY_EVROTRUST: this.$pgettext('identification_status_alert_CLIENT_REJECTED_BY_EVROTRUST', 'Registration cannot be completed at the moment due to invalid identification. Please check your Evrotrust app for further information and restart the registration once you\'ve successfully been identified in Evrotrust app.'),
          NOT_A_CLIENT: this.$pgettext('identification_status_alert_NOT_A_CLIENT', 'Register youself as a user in Evrotrust app and complete identification to continue.'),
          CLIENT_NOT_IDENTIFIED: this.$pgettext('identification_status_alert_CLIENT_NOT_IDENTIFIED', 'Complete identification in Evrotrust app to continue.'),
          CLIENT_HAS_NOT_CONFIRMED_EMAIL_AND_PHONE_NUMBER: this.$pgettext('identification_status_alert_CLIENT_HAS_NOT_CONFIRMED_EMAIL_AND_PHONE_NUMBER', 'Validate Your email address & mobile number in Evrotrust app to complete identification.'),
          MOBILE_VALIDATION_NOT_FINISHED: this.$pgettext('identification_status_alert_MOBILE_VALIDATION_NOT_FINISHED', 'Validate Your mobile number in Evrotrust app to complete identification.'),
          EMAIL_VALIDATION_NOT_FINISHED: this.$pgettext('identification_status_alert_EMAIL_VALIDATION_NOT_FINISHED', 'Validate Your email address in Evrotrust app to complete identification.'),
          ACCEPT: this.$pgettext('identification_status', 'Accept Bigbank\'s request in Evrotrust app to obtain your identification data to continue'),
          PROCESSING: this.$pgettext('identification_status', 'We\'ve received your data from Evrotrust. Please wait a moment until we process the data.'),
          ERROR: this.$pgettext('identification_status', 'In order to complete the identification process, please contact our customer service.')
        },
        refreshButton: {
          IDENTIFICATION_COMPLETED: this.$pgettext('refresh_button', 'Identification completed')
        }
      }
    },
    isContinueButtonShown () {
      return this.isRegistrationCompletedSuccessfully && !this.hasAmlAndScreeningCheckFailed && this.continueLink
    },
    registrationStatus () {
      return this.status.status
    },
    continueLink () {
      return this.status.continueUrl
    },
    identificationStatus () {
      const status = this.status?.process?.identificationStatus

      // Handle deprecated value
      if (status === this.constants.IDENTIFICATION_STATUSES.IDENTIFIED) {
        if (this.previousStep === steps.CHECK_IDENTIFICATION_STATUS) {
          return this.constants.IDENTIFICATION_STATUSES.PENDING
        } else {
          return this.constants.IDENTIFICATION_STATUSES.COMPLETED
        }
      }

      return status
    },
    identificationStatusReason () {
      return this.status.process ? this.status.process.identificationStatusReason : undefined
    },
    customerCountryCode () {
      const customerCountry = this.customerCitizenCountryCode ? this.customerCitizenCountryCode : ''

      return customerCountry.toUpperCase()
    },
    isBulgarianCitizen () {
      if (!this.customerCountryCode) {
        return undefined
      }

      return this.customerCountryCode === this.constants.BG
    },
    hasIdentificationData () {
      return typeof this.customerCitizenCountryCode !== 'undefined'
    },
    isIdentifiedAsNonBulgarianCitizen () {
      return this.identificationStatus === this.constants.IDENTIFICATION_STATUSES.COMPLETED && this.isBulgarianCitizen === false
    },
    previousStep () {
      return this.status.process ? this.status.process.previousCompletedStep : undefined
    },
    alertTexts () {
      let alertText = null

      if (this.isRegistrationCompletedSuccessfully || this.isBulgarianCitizen === false) {
        return []
      }

      if (this.identificationStatusReason) {
        alertText = this.translations.identificationStatusAlerts[this.identificationStatusReason]
      } else if (this.isWaitingIdentificationData) {
        alertText = this.translations.identificationStatusAlerts.ACCEPT
      } else if (this.status.hasErrors && this.isCustomerIdentified) {
        alertText = this.translations.identificationStatusAlerts.ERROR
      } else if (!this.isWaitingIdentificationData) {
        alertText = this.translations.identificationStatusAlerts.PROCESSING
      }

      let alertTexts = []
      if (alertText) {
        alertTexts = this.parseTextTranslation(alertText)
      }

      return alertTexts
    },
    refreshButton () {
      if (!this.isWaitingIdentificationData && !this.isCustomerIdentified && !this.identificationStatusReason) {
        return null
      }

      if (this.isIdentifiedAsNonBulgarianCitizen) {
        return null
      }

      const isLoading = this.refreshLoading || this.status.locked || this.isCustomerIdentified || this.isWaitingIdentificationData

      return {
        label: this.translations.refreshButton.IDENTIFICATION_COMPLETED,
        loading: isLoading,
        disabled: isLoading
      }
    },
    isRegistrationCompletedSuccessfully () {
      return this.registrationStatus === this.constants.REGISTRATION_STATUSES.COMPLETED
    },
    hasAmlAndScreeningCheckFailed () {
      return this.status.hasAmlAndScreeningCheckFailed
    },
    isCustomerIdentified () {
      return [
        this.constants.IDENTIFICATION_STATUSES.COMPLETED,
        this.constants.IDENTIFICATION_STATUSES.EXISTING_CUSTOMER
      ].includes(this.identificationStatus)
    },
    isExistingCustomer () {
      return this.identificationStatus === this.constants.IDENTIFICATION_STATUSES.EXISTING_CUSTOMER
    },
    isWaitingIdentificationData () {
      return this.identificationStatus === this.constants.IDENTIFICATION_STATUSES.PENDING
    },
    title () {
      if (this.isIdentifiedAsNonBulgarianCitizen) {
        return this.translations.notBulgarianTitle
      }

      return this.isRegistrationCompletedSuccessfully
        ? this.translations.welcomeBackTitle
        : this.translations.newCustomerTitle
    },
    identifiedCustomerTexts () {
      return this.parseTextTranslation(this.translations.identifiedCustomerText)
    },
    notBulgarianCitizenTexts () {
      return this.parseTextTranslation(this.translations.notBulgarianCustomerText)
    },
    notValidatedCustomerTexts () {
      return this.parseTextTranslation(this.translations.notValidatedCustomerText)
    },
    notRegisteredCustomerTexts () {
      return this.parseTextTranslation(this.translations.notRegisteredCustomerText)
    },
    registeredCustomerTexts () {
      return this.parseTextTranslation(this.translations.registeredCustomerText)
    },
    amlScreeningCheckFailedTexts () {
      return this.parseTextTranslation(this.translations.amlScreeningCheckFailedText)
    },
    activeTexts () {
      if (this.isRegistrationCompletedSuccessfully && !this.hasAmlAndScreeningCheckFailed) {
        return this.registeredCustomerTexts
      } else if (this.hasAmlAndScreeningCheckFailed) {
        return this.amlScreeningCheckFailedTexts
      } else if (this.isIdentifiedAsNonBulgarianCitizen) {
        return this.notBulgarianCitizenTexts
      } else {
        switch (this.identificationStatus) {
          case this.constants.IDENTIFICATION_STATUSES.NOT_REGISTERED: // case 1
            return this.notRegisteredCustomerTexts
          case this.constants.IDENTIFICATION_STATUSES.NOT_VALIDATED: // cases 2-5
            return this.notValidatedCustomerTexts
          case this.constants.IDENTIFICATION_STATUSES.PENDING: // cases 6-7
            return this.identifiedCustomerTexts
          default:
            return []
        }
      }
    },
    googlePlayStoreLink () {
      const channel = this.channel ? this.channel : ''
      switch (channel.toLowerCase()) {
        case 'bg':
          return 'https://play.google.com/store/apps/details?id=com.evrotrust.app'
        default:
          return ''
      }
    },
    appleAppStoreLink () {
      const channel = this.channel ? this.channel : ''
      switch (channel.toLowerCase()) {
        case 'bg':
          return 'https://apps.apple.com/us/app/evrotrust/id1195807317'
        default:
          return ''
      }
    },
    showEvrotrustLogo () {
      return !this.isRegistrationCompletedSuccessfully && !this.hasAmlAndScreeningCheckFailed && !this.isExistingCustomer && this.isBulgarianCitizen !== false
    },
    hasAlertTexts () {
      return this.alertTexts && this.alertTexts.length > 0
    },
    hasManyAlertTexts () {
      return this.alertTexts && this.alertTexts.length > 1
    }
  },
  methods: {
    ...mapActions(useAppStore, ['refresh']),
    parseTextTranslation (rawText) {
      const parsedTexts = []

      if (rawText && rawText.length > 0) {
        const texts = rawText
          .replace(/\n/g, ' ')
          .split('{paragraph}')

        for (const text of texts) {
          const textObject = {
            text: '',
            list: false,
            appsSectionDownload: false
          }

          if (text.includes('{list_item}')) {
            const items = text.split('{list_item}')

            textObject.text = items.shift()
            textObject.list = items
          } else if (text.includes('{apps_download_section}')) {
            textObject.text = text.replace('{apps_download_section}', '')
            textObject.appsSectionDownload = true
          } else {
            textObject.text = text
          }

          parsedTexts.push(textObject)
        }
      }

      return parsedTexts
    },
    onRefresh () {
      if (this.refreshLoading) {
        return false
      }

      this.refreshLoading = true

      this.refresh()
        .finally(() => {
          this.refreshLoading = false
        })
    },
    openEvrotrustMobileApp () {
      if (!isMobile()) {
        return
      }

      if (this.isUserSentToTheEvrotrustApp) {
        return
      }

      this.isUserSentToTheEvrotrustApp = true
      document.location = 'evrotrust://'
    },
    scrollToElement (elementRef) {
      const el = this.$refs[elementRef]

      if (!el || !el.scrollIntoView) {
        return
      }

      el.scrollIntoView({ behavior: 'smooth' })
    }
  },
  updated () {
    this.$nextTick(() => {
      if (this.isWaitingIdentificationData) {
        this.openEvrotrustMobileApp()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .thank-you {
    &__header {
      text-align: center;
    }

    &__title-text {
      font-family: $max-pro-bold;
      font-size: $h4-size;
      color: $navy;
      margin-top: 10px;
    }

    &__paragraph {
      margin-top: 15px;
    }

    &__list-item {
      color: $gray;
    }

    &__download-app {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .powered-by {
    &__text {
      font-family: $gotham-medium;
      font-size: $smallest-font-size;
      color: $gray-90;
      margin-top: 30px;
    }

    &__logo {
      text-align: center;
      height: 32px;
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }

  .log-in-button-desktop {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .alert {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &__paragraph {
      margin-top: 15px;
      margin-bottom: 5px;
      text-align: center;
      color: $gray-90;
      font-size: $smallest-font-size;
    }

    &__many {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
</style>
