<template>
  <bb-modal
    :hideClose="hideClose"
    :visible="visible"
    @close="onClose"
    flavor="card"
    color="white"
    publicSize="m"
    fullMobile
    centerVertically
    class="sign-modal__wrapper">
    <slot name="heading" slot="heading" />
    <div v-if="showLoader" class="sign-modal__wrapper__loader">
      <bb-spinner />
      <div class="sign-modal__wrapper__loader__text">{{ translations.loadingText }}</div>
    </div>
    <slot v-else />
    <slot name="footer" slot="footer" />
  </bb-modal>
</template>

<script>
import { BbModal, BbSpinner } from '@bigbank/interface-components'

export default {
  name: 'signing-loading',
  components: {
    BbSpinner,
    BbModal
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    showLoader: {
      type: Boolean,
      required: true,
      default: false
    },
    heading: {
      type: String,
      required: false,
      default: ''
    },
    hideClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    translations () {
      return {
        loadingText: this.$pgettext('signing_loading', 'This will take a moment, please hold tight!')
      }
    }
  },
  methods: {
    onClose (e) {
      if (e?.eventSource !== 'click-outside') {
        this.$emit('close', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-modal__wrapper {
  h3.bb-modal__title {
    font-family: $gotham-bold;
    color: $blue;
    font-size: $default-font-size;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;

    &__text {
      padding-top: 48px;
      font-family: $gotham-medium;
      font-size: $font-size-smallest;
      color: $gray;
    }
  }
}
</style>
