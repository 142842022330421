<template>
  <thank-you-wrapper>
    <bb-public-header
      slot="title"
      :title="translations.title"
      icon="client-success"
      :subtitle="translations.subtitle"
    ></bb-public-header>
    <template slot="text">
      <bb-accordion v-if="isGermany" :value="accordions" flat class="nm-t-10">
        <template slot="post-office-accordion">
          <thank-you-steps
              :items="translations.postOfficeStepItems"
              class="nm-t-25 nm-l-15"
          ></thank-you-steps>
          <div class="d-flex justify-content-center m-t-30">
            <deutchen-post-button />
          </div>
        </template>
        <template slot="video-call-accordion">
          <thank-you-steps
              :items="translations.videoCallStepItems"
              class="nm-t-25 nm-l-15"
          ></thank-you-steps>
          <div class="d-flex justify-content-center m-t-30">
            <deutchen-post-button />
          </div>
          <thank-you-checklist
              :title="translations.videoCallChecklistTitle"
              :items="translations.videoCallCheckListItems"
              class="m-t-35 nm-b-5"
          ></thank-you-checklist>
        </template>
      </bb-accordion>
      <bb-box v-else thin>
        <thank-you-steps
            :title="translations.stepsTitle"
            :items="translations.stepItems"
            class="m-t-10 m-r-15"
        ></thank-you-steps>
        <div v-if="!isNetherlands" class="d-flex justify-content-center m-t-30">
          <deutchen-post-button />
        </div>
        <thank-you-checklist
          :title="translations.checkListTitle"
          :items="translations.checkListItems"
          class="m-t-35 m-l-15 m-r-15"
        ></thank-you-checklist>
      </bb-box>
    </template>
  </thank-you-wrapper>
</template>

<script>
import { BbAccordion, BbBox, BbPublicHeader } from '@bigbank/interface-components'
import ThankYouWrapper from '@/components/ThankYouWrapper'
import DeutchenPostButton from '@/components/DeutchenPostButton'
import ThankYouSteps from '@/components/ThankYouSteps'
import ThankYouChecklist from '@/components/ThankYouChecklist'
import { mapState } from 'pinia'

import '@bigbank/interface-components/dist/svg/client/success'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'cb-thank-you',
  components: {
    BbAccordion,
    BbBox,
    BbPublicHeader,
    DeutchenPostButton,
    ThankYouWrapper,
    ThankYouSteps,
    ThankYouChecklist
  },
  computed: {
    ...mapState(useAppStore, ['featureFlags', 'channel']),
    translations () {
      switch (this.channel.toLowerCase()) {
        case 'at':
          return {
            title: this.$pgettext('thank-you-at', 'Thank you for signing up!'),
            subtitle: this.$pgettext('thank-you-at', 'To complete your registration, please get identified with the German Post.'),
            stepsTitle: this.$pgettext('thank-you-at', 'Steps:'),
            stepItems: [
              { key: 1, value: this.$pgettext('thank-you-at', 'Download app (can also do in browser)') },
              { key: 2, value: this.$pgettext('thank-you-at', 'Identification via video chat') },
              { key: 3, value: this.$pgettext('thank-you-at', 'Receive logins to online banking from Bigbank') }
            ],
            checkListTitle: this.$pgettext('thank-you-at', 'Checklist'),
            checkListItems: [
              {
                key: 1,
                value: this.$pgettext('thank-you-at', 'Working webcam & microphone on pc or smartphone with postident app'),
                links: [
                  {
                    key: 1,
                    text: this.$pgettext('thank-you-de', 'for Android'),
                    url: 'https://play.google.com/store/apps/details?id=de.deutschepost.postident'
                  },
                  {
                    key: 2,
                    text: this.$pgettext('thank-you-de', 'or'),
                    url: false
                  },
                  {
                    key: 3,
                    text: this.$pgettext('thank-you-de', 'for Apple'),
                    url: 'https://itunes.apple.com/at/app/postident-durch-videochat/id999762739?mt=8'
                  }
                ]
              },
              { key: 2, value: this.$pgettext('thank-you-at', 'Id card or passport at hand') },
              { key: 3, value: this.$pgettext('thank-you-at', 'Mobile phone at hand for receiving mTAN via SMS') }
            ]
          }
        case 'de':
          return {
            title: this.$pgettext('thank-you-de', 'Thank you for signing up!'),
            subtitle: this.$pgettext('thank-you-de', 'To complete your registration, please get identified with the German Post.'),
            postOfficeAccordionTitle: this.$pgettext('thank-you-de', 'Post Office'),
            postOfficeStepItems: [
              { key: 1, value: this.$pgettext('thank-you-de', 'Save/print coupon (Postident Coupon (printed or on Smartphone)') },
              { key: 2, value: this.$pgettext('thank-you-de', 'Identification at post office (Id card or passport)') },
              { key: 3, value: this.$pgettext('thank-you-de', 'Receive logins to online banking from Bigbank') }
            ],
            videoCallAccordionTitle: this.$pgettext('thank-you-de', 'Video Call'),
            videoCallStepItems: [
              { key: 1, value: this.$pgettext('thank-you-de', 'Download app (can also do in browser)') },
              { key: 2, value: this.$pgettext('thank-you-de', 'Identification via video chat') },
              { key: 3, value: this.$pgettext('thank-you-de', 'Receive logins to online banking from Bigbank') }
            ],
            videoCallChecklistTitle: this.$pgettext('thank-you-de', 'Checklist'),
            videoCallCheckListItems: [
              {
                key: 1,
                value: this.$pgettext(
                  'thank-you-de',
                  'Working webcam & microphone on pc or smartphone with postident app'
                ),
                links: [
                  {
                    key: 1,
                    text: this.$pgettext('thank-you-de', 'for Android'),
                    url: 'https://play.google.com/store/apps/details?id=de.deutschepost.postident&hl=de'
                  },
                  {
                    key: 2,
                    text: this.$pgettext('thank-you-de', 'or'),
                    url: false
                  },
                  {
                    key: 3,
                    text: this.$pgettext('thank-you-de', 'for Apple'),
                    url: 'https://itunes.apple.com/de/app/postident-durch-videochat/id999762739?mt=8'
                  }
                ]
              },
              { key: 2, value: this.$pgettext('thank-you-de', 'Id card or passport at hand') },
              { key: 3, value: this.$pgettext('thank-you-de', 'Mobile phone at hand for receiving mTAN via SMS') }
            ]
          }
        case 'nl':
          return {
            title: this.$pgettext('thank-you-nl', 'Thank you for signing up!'),
            subtitle: this.$pgettext('thank-you-nl', 'To complete Your registration, please get identified via our partner AMP Groep.'),
            stepsTitle: this.$pgettext('thank-you-nl', 'Steps:'),
            stepItems: [
              { key: 1, value: this.$pgettext('thank-you-nl', 'AMP will contact you withing 2 business days') },
              { key: 2, value: this.$pgettext('thank-you-nl', 'Choose a place and time for your appointment with AMP') },
              { key: 3, value: this.$pgettext('thank-you-nl', 'Identification by an AMP employee at the scheduled time and place') },
              { key: 4, value: this.$pgettext('thank-you-nl', 'Receive logins for online banking from Bigbank') }
            ],
            checkListTitle: this.$pgettext('thank-you-nl', 'Checklist'),
            checkListItems: [
              { key: 1, value: this.$pgettext('thank-you-nl', 'Please be ready at the scheduled time and place') },
              { key: 2, value: this.$pgettext('thank-you-nl', 'ID card, passport or driving license at hand') }
            ]
          }
      }

      return {}
    },
    isGermany () {
      return this.channel.toLowerCase() === 'de'
    },
    isNetherlands () {
      return this.channel.toLowerCase() === 'nl'
    },
    accordions () {
      return [
        {
          id: 'post-office-accordion',
          title: this.translations.postOfficeAccordionTitle,
          open: true
        },
        {
          id: 'video-call-accordion',
          title: this.translations.videoCallAccordionTitle
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
