<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: false,
      errorText: translations.errors.errorText,
      submitText: translations.submitText,
      disabled: isLoading,
      loading: isLoading
    }"
    @submit="onSubmit"
  >
    <bb-public-header
      slot="header"
      :title="translations.header"
      icon="client-personal"
    />
    <p v-if="errorMessage" class="f-color-red align-center f-gotham f-small m-t-10 m-b-20">
      {{ errorMessage }}
    </p>
    <template>
      <bb-input
        v-model="form.contactAddress.streetAddress"
        v-validate="'required'"
        :label="translations.contactStreetAddress"
        :data-vv-as="translations.contactStreetAddress"
        name="contactStreetAddress"
        @change="onAddressDetailsChange"
      />
      <bb-input
        v-model="form.contactAddress.houseNumber"
        :label="translations.contactHouseNumber"
        :data-vv-as="translations.contactHouseNumber"
        name="contactHouseNumber"
        @change="onAddressDetailsChange"
      />
      <bb-input
        v-model="form.contactAddress.flatNumber"
        :label="translations.contactFlatNumber"
        :data-vv-as="translations.contactFlatNumber"
        name="contactFlatNumber"
      />
      <bb-input
        v-model="form.contactAddress.city"
        v-validate="'required'"
        :label="translations.contactCity"
        :data-vv-as="translations.contactCity"
        name="contactCity"
        @change="onAddressDetailsChange"
      />
      <bb-input
        v-model="form.contactAddress.county"
        v-validate="'required'"
        :label="translations.contactCounty"
        :data-vv-as="translations.contactCounty"
        name="contactCounty"
      />
      <bb-masked-input
        :value="form.contactAddress.postcode"
        mask="LT-11111"
        @input="handleContactPostcodeChange"
        v-validate="'required|lt_contact_postal_code'"
        :label="translations.contactPostalCode"
        :data-vv-as="translations.contactPostalCode"
        name="contactPostalCode"
      />
      <bb-select
        v-model="form.contactAddress.country"
        v-validate="'required'"
        :options="countryOptionsWithExcludedStateless"
        :label="translations.contactCountry"
        :data-vv-as="translations.contactCountry"
        @change="onContactCountryChange"
        name="contactCountry"
      />
    </template>
  </bb-application-page>
</template>

<script>
import RegistrationMixin from '../common/RegistrationMixin'
import { cleanInput } from '../common/util'
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbSelect,
  BbMaskedInput
} from '@bigbank/interface-components'
import { mapActions, mapState } from 'pinia'
import errorHandler from '@/errors/errorHandler'
import { STATELESS_COUNTRY_CODE } from '@/const'
import { LTValidator } from '@bigbank/dc-common/validators/lt/LTValidator.class'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'
import { useLithuaniaStore } from '@/store/lithuania.store'

const ltValidator = new LTValidator()

export default {
  name: 'lt-address-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbSelect,
    BbMaskedInput
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      form: {
        contactAddress: {
          streetAddress: undefined,
          houseNumber: undefined,
          flatNumber: undefined,
          city: undefined,
          postcode: undefined,
          county: undefined,
          country: 'LT'
        }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['isCustomerIdentificationRequired', 'status', 'formData', 'countries']),
    translations () {
      return {
        header: this.$pgettext('registration', 'Address Details'),
        contactStreetAddress: this.$pgettext('registration', 'Street name'),
        contactHouseNumber: this.$pgettext('registration', 'Street number'),
        contactFlatNumber: this.$pgettext('registration', 'Apartment/house number'),
        contactCity: this.$pgettext('registration', 'City'),
        contactCounty: this.$pgettext('registration', 'County'),
        contactPostalCode: this.$pgettext('registration', 'Postal code'),
        contactCountry: this.$pgettext('registration', 'Country'),
        countryTooltip: this.$pgettext('registration_lt', 'COUNTRY_HELP_TEXT'),
        submitText: this.$pgettext('registration', 'SUBMIT_FORM_BUTTON_TEXT'),
        errors: {
          invalidPostalCode: this.$pgettext('registration', 'Postal code has invalid format'),
          errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE')
        }
      }
    },
    countryOptionsWithExcludedStateless () {
      return this.getCountriesList([STATELESS_COUNTRY_CODE])
    },
    commonInitialValues () {
      const customer = this.status?.customer

      return [
        {
          name: 'contactAddress',
          value: {
            streetAddress: customer?.contactAddress?.streetAddress ?? undefined,
            houseNumber: customer?.contactAddress?.houseNumber ?? undefined,
            flatNumber: customer?.contactAddress?.flatNumber ?? undefined,
            city: customer?.contactAddress?.city ?? undefined,
            postcode: customer?.contactAddress?.postcode ?? undefined,
            county: customer?.contactAddress?.county ?? undefined,
            country: customer?.contactAddress?.country || CountryChannel.LT
          }
        }
      ]
    }
  },
  watch: {
    isCustomerIdentificationRequired: {
      async handler () {
        await this.goToNextStep()
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep', 'getCountries']),
    ...mapActions(useLithuaniaStore, ['getPostcode']),
    getCountriesList (excludeByCode) {
      let countries = this.countries.map(country => ({
        value: country.countryCode,
        text: country.translation
      }))
        .sort((a, b) => a.text.localeCompare(b.text, this.locale))

      if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value))
      }

      return countries
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        await this.updateFormData({ addressDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    async onAddressDetailsChange () {
      try {
        if (this.form.contactAddress.streetAddress && this.form.contactAddress.houseNumber && this.form.contactAddress.city) {
          this.isLoading = true
          await this.updateFormData({ addressDetails: this.form })
          await this.getPostcode()
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    handleContactPostcodeChange (value) {
      this.form.contactAddress.postcode = cleanInput(value)
    },
    onContactCountryChange () {
      this.$validator.validate('contactPostalCode')
    }
  },
  async created () {
    try {
      this.$validator.extend('lt_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => ltValidator.isValidPostalCode(value)
      })
      this.$validator.extend('lt_contact_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => {
          if (this.form.contactAddress.country !== 'LT') {
            return this.form.contactAddress.postcode.length >= 3
          }

          return ltValidator.isValidPostalCode(value)
        }
      })

      await this.getCountries()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
