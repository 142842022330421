<template>
  <div class="selector">
    <div class="icon-holder">
      <bb-icon
        name="illustration-no-account"
        fill="default"
        size="fluid"
        animated
      />
    </div>
    <h3 class="title m-b-50">{{translations.title}}</h3>
    <div class="legal" v-sanitize.basic="legalText" />
    <bb-button class="m-t-40" :label="translations.register" @click="login" />
    <bb-link class="link m-t-40" :label="translations.login" :to="loginUrl" v-if="showExistingClientLink" />
  </div>
</template>

<script>
import { BbIcon, BbButton, BbLink } from '@bigbank/interface-components'
import '@bigbank/interface-components/dist/svg/illustration/no-account'
import { mapState } from 'pinia'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'

export default {
  components: {
    BbIcon,
    BbButton,
    BbLink
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'channelConfig', 'language', 'status', 'isCustomer']),
    translations () {
      return {
        title: this.$pgettext('landing', 'Become a customer'),
        legalText: this.$pgettext('landing', 'LEGAL TEXT <a href="https://bigbank.eu" target="_blank">WITH LINKS SUPPORT</a>'),
        legalTextLv: this.$pgettext('landing_lv', 'LEGAL TEXT FOR LV ONLY <a href="https://bigbank.eu" target="_blank">WITH LINKS SUPPORT</a>'),
        register: this.$pgettext('landing', 'Register as client'),
        login: this.$pgettext('landing', 'Log in as existing client')
      }
    },
    legalText () {
      if (this.channel === CountryChannel.LV) {
        return this.translations.legalTextLv
      } else {
        return this.translations.legalText
      }
    },
    loginUrl () {
      return this.status.loginUrl ?? `${this.channelConfig.selfserviceUrl}?language=${this.language}`
    },
    showExistingClientLink () {
      return this.isCustomer !== false
    }
  },
  methods: {
    login () {
      document.location = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
.selector {
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 376px;
  align-self: center;

  .icon-holder {
    width: 220px;
    height: 220px;
  }

  .title {
    color: $blue;
    font-family: $gotham-medium;
    font-size: $h4-size;
    text-align: center;
    margin-top: -15px;
  }

  .legal {
    font-size: $smallest-font-size;
    text-align: center;
  }

  .link {
    font-size: $small-font-size;
  }
}
</style>

<style lang="scss">
body {
  background-color: $gray-10;
}

.legal a {
  color: $blue;
}
</style>
