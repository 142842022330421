import Vue from 'vue';
import router from './views';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueRouter from 'vue-router';
import App from './App.vue';
import GetTextPlugin from 'vue-gettext';
import * as translations from './../../translations/translations.json';
import VeeValidate from 'vee-validate';
import VSanitize from 'v-sanitize';
import handleError from './errors/errorHandler';
import VeeBg from 'vee-validate/dist/locale/bg';
import VeeDe from 'vee-validate/dist/locale/de';
import VeeNl from 'vee-validate/dist/locale/nl';
import VeeLv from 'vee-validate/dist/locale/lv';
import VeeEn from 'vee-validate/dist/locale/en';
import VeeFi from 'vee-validate/dist/locale/fi';
import VeeSv from 'vee-validate/dist/locale/sv';
import VeeLt from 'vee-validate/dist/locale/lt';
import VeeEt from 'vee-validate/dist/locale/et';
import VeeRu from 'vee-validate/dist/locale/ru';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { apm } from '@elastic/apm-rum';
import VueGtm from '@gtm-support/vue2-gtm';
import '@bigbank/interface-components/dist/lib/esm.css';
/**
 * Please add all components separately here so we have reduced bundle (import only what we need)
 *
 * PS! Try to keep ascending order
 */
import { BbButton, BbInput, BbPublicHeader } from '@bigbank/interface-components';
// Register IC components one-by-one
[
    BbButton,
    BbInput,
    BbPublicHeader
].forEach((component) => Vue.component(component.name, component));
// @ts-expect-error
window.Vue = Vue;
Vue.use(PiniaVuePlugin);
if (window.APM_CONFIG) {
    Vue.use(ApmVuePlugin, {
        router,
        config: {
            serviceName: 'digital-channels-welcome_service_frontend_web',
            serverUrl: 'https://apm.bigbank.eu:443',
            serviceVersion: process.env.VUE_APP_GIT_HASH,
            environment: window.APM_CONFIG.env,
            active: window.APM_CONFIG.enable
        },
        apm
    });
    apm.addLabels({ team: 'dcp', env: window.APM_CONFIG.env });
}
if (window.APM_CONFIG && window.APM_CONFIG.enable) {
    Vue.config.errorHandler = function (err) {
        apm.captureError(err);
        handleError(err);
    };
    Vue.config.silent = true;
}
else {
    Vue.config.errorHandler = (err) => handleError(err);
}
if (window.GTM) {
    Vue.use(VueGtm, {
        id: window.GTM, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: window.nonce, // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    });
}
Vue.use(VeeValidate, {
    classes: true,
    locale: window.LANG || 'en',
    dictionary: {
        lv: VeeLv,
        de: VeeDe,
        nl: VeeNl,
        bg: VeeBg,
        en: VeeEn,
        fi: VeeFi,
        sv: VeeSv,
        et: VeeEt,
        lt: VeeLt,
        ru: VeeRu
    }
});
Vue.use(VSanitize);
Vue.config.productionTip = false;
Vue.use(GetTextPlugin, {
    defaultLanguage: window.LANG || 'en',
    languageVmMixin: {
        computed: {
            currentKebabCase: function () {
                return this.current.toLowerCase().replace('_', '-');
            }
        }
    },
    translations
});
const pinia = createPinia();
Vue.use(VueRouter);
new Vue({
    router,
    pinia,
    render: (h) => h(App) // eslint-disable-line @typescript-eslint/explicit-function-return-type
}).$mount('#app');
