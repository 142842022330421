<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalAndCompanyDetails'
import AdditionalDetails from './AdditionalDetails'
import AddressDetails from './AddressDetails'
import CommonRegistration from '@/views/common/CommonRegistration'
import { mapActions } from 'pinia'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/ee/steps.config'
const { CUSTOMER_TYPE } = require('@/const')

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  methods: {
    ...mapActions(useAppStore, ['setCustomerType'])
  },
  computed: {
    views () {
      return stepsConfig.legal
    }
  },
  created () {
    this.setCustomerType(CUSTOMER_TYPE.Legal)
  },
  extends: CommonRegistration
}
</script>
