<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :phone-number-prefix-choice="phoneNumberPrefixChoice"
      :channel="channel"
      :locale="language"
      :translations-overrides="translations"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :personal-identity-code-mask="personalIdentityCodeMask"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails'
import { LTPersonalId } from '@bigbank/dc-common/validators/lt/LTPersonalId.class'
import { cleanInput } from '@/views/common/util'
import { LTValidator } from '@bigbank/dc-common/validators/lt/LTValidator.class'
import errorHandler from '@/errors/errorHandler'
import { useAppStore } from '@/store/app.store'

const ltPhoneValidator = require('@bigbank/dc-common/validators/lt/phoneNumber').default
const ltValidator = new LTValidator()

export default {
  name: 'personal-and-company-details-lt-legal',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+370',
      phoneNumberPrefixChoice: ['+370', '+372', '+371'],
      personalIdentityCodeMask: '11111111111',
      dateFormat: 'DD.MM.YYYY',
      form: {
        personalIdentityCode: undefined,
        companyName: undefined,
        companyRegistrationCode: undefined,
        bankAccountNumber: undefined,
        firstName: undefined,
        surname: undefined,
        dateOfBirth: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        externalRegistryCheckConsent: null,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: true,
      fieldDefinitions: [
        { name: 'companyName' },
        { name: 'companyRegistrationCode' },
        {
          name: 'bankAccountNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid IBAN account number'),
            validate: value => ltValidator.isValidIBAN(value, true)
          }
        },
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext(
              'registration',
              'Please insert valid ID number'
            ),
            validate: (value) => new LTPersonalId(value).isValid()
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext(
              'registration',
              'Please insert valid phone number'
            ),
            validate: (value) =>
              ltPhoneValidator(cleanInput(value), this.channel)
          }
        },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'countries']),
    translations () {
      return {
        header: this.$pgettext('registration_legal', 'Personal and company details'),
        subtitle: this.$pgettext('registration_legal', 'Please fill this form below to open new deposit for the company you represent')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep', 'getCountries']),
    async fillPhoneNumberPrefixes () {
      await this.getCountries()

      this.phoneNumberPrefixChoice = this.countries
        .filter((country) => country.areaCodeAllowed)
        .map((country) => country.areaCode.replace(' ', ''))
        .sort((a, b) => (a > b ? 1 : -1))

      this.phoneNumberPrefixChoice = Array.from(
        new Set(this.phoneNumberPrefixChoice)
      )
    }
  },
  async created () {
    try {
      this.fillPhoneNumberPrefixes()
    } catch (err) {
      await errorHandler(err)
    }
  }
}
</script>
