<template>
  <common-layout
    :progressBar="{
      max: 2,
      value: step + 1,
      color: 'blue',
    }"
  >
    <router-view />
  </common-layout>
</template>

<script>
import RoutingMixin from '../common/RoutingMixin'
import CommonLayout from './../../components/CommonLayout'

export default {
  components: {
    CommonLayout
  },
  mixins: [
    RoutingMixin
  ]
}
</script>
