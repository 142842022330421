<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: false,
      errorText: translations.errors.errorText,
      submitText: translations.submitText,
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="onSubmit"
  >
    <bb-public-header
      slot="header"
      icon="client-personal"
      :subtitle="translations.subtitle"
    />
    <p v-if="errorMessage" class="f-color-red align-center f-gotham f-small m-t-10 m-b-20">
      {{errorMessage}}
    </p>
    <bb-input
      name="clearingNumber"
      v-validate="'required|se_clearing_number'"
      v-model="form.clearingNumber"
      :label="translations.clearingNumber"
      :data-vv-as="translations.clearingNumber"
    />
    <bb-input
      name="bankAccountNumber"
      v-validate="'required|se_bank_account_number'"
      v-model="form.bankAccountNumber"
      :label="translations.bankAccountNumber"
      :data-vv-as="translations.bankAccountNumber"
    />
    <br/>
    <label class="money-origin-label">
      <span>{{ translations.justMoneyOrigin }}</span>
    </label>
    <bb-checkbox-group
      name="moneyOrigin"
      :value="moneyOriginOptions"
      :errorMessage="translations.errors.checkboxValidation"
      single
      @input="handleMoneyOriginInput"
    />
    <bb-input
      v-if="isOtherMoneyOriginShown"
      v-model="form.otherMoneyOrigin"
      name="otherMoneyOrigin"
      v-validate="'required'"
      :label="translations.otherMoneyOrigin"
      :data-vv-as="translations.otherMoneyOrigin"/>
    <bb-checkbox
      v-model="form.moneyDetailsConsent"
      name="moneyDetailsConsent"
      background
      class="p-t-30"
      v-validate="'required'"
    >
      {{translations.moneyDetailsConsentText}}
    </bb-checkbox>
    <bb-checkbox
      v-model="form.depositConsent"
      name="depositConsent"
      v-validate="'required'"
      background
      class="p-t-30"
    >
      <text-with-links
        :text="translations.depositConsentText"
        :links="[]"
      />
    </bb-checkbox>
  </bb-application-page>
</template>

<script>
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbCheckbox,
  BbCheckboxGroup
} from '@bigbank/interface-components'
import { mapActions, mapState } from 'pinia'
import { validateAccount } from '@bigbank/dc-common/validators/se/accountNumber'
import * as validateClearingNumber from '@bigbank/dc-common/validators/se/clearingNumber'
import RegistrationMixin from '../common/RegistrationMixin'
import TextWithLinks from '../../components/TextWithLinks'
import api from '../../api'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'deposit-details',
  data () {
    return {
      isLoading: true,
      moneyOriginValues: [],
      form: {
        clearingNumber: undefined,
        bankAccountNumber: undefined,
        moneyOrigins: [],
        otherMoneyOrigin: undefined,
        moneyDetailsConsent: undefined,
        depositConsent: undefined
      }
    }
  },
  mixins: [
    RegistrationMixin
  ],
  components: {
    BbInput,
    BbApplicationPage,
    BbPublicHeader,
    BbCheckbox,
    BbCheckboxGroup,
    TextWithLinks
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep']),
    handleMoneyOriginInput (values) {
      this.form.moneyOrigins = values.filter(value => value.checked).map(value => value.name)
    },
    async fetchMoneyOriginValues () {
      this.moneyOriginValues = await api.getLookupValuesByCode('MONEY_ORIGIN_PRIVATE')
    },
    toCleanedTitleCase (str) {
      str = str.replace(/_/g, ' ')
      str = str.toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1, str.length)
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        await this.updateFormData({ depositDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['formData']),
    translations () {
      return {
        subtitle: this.$pgettext('registration', 'Deposit details'),
        clearingNumber: this.$pgettext('registration', 'Clearing number'),
        bankAccountNumber: this.$pgettext('registration', 'Bank account number'),
        justMoneyOrigin: this.$pgettext('registration', 'Money origin'),
        moneyOrigin: {
          INCOME_BUSINESS_ACTIVITY: this.$pgettext('registration', 'Business activity income'),
          GIFT: this.$pgettext('registration', 'Gift'),
          SALARY: this.$pgettext('registration', 'Salary'),
          INHERITED: this.$pgettext('registration', 'Inherited'),
          RETIREMENT_BENEFIT: this.$pgettext('registration', 'Retirement benefit'),
          PROPERTY_SALE: this.$pgettext('registration', 'Property sale'),
          SAVINGS: this.$pgettext('registration', 'Savings'),
          RETURN_ON_INVESTMENT: this.$pgettext('registration', 'Return on investment'),
          OTHER: this.$pgettext('registration', 'Other')
        },
        otherMoneyOrigin: this.$pgettext('registration', 'Other money origin'),
        moneyDetailsConsentText: this.$pgettext('registration', 'MONEY_DETAILS_CONSENT_TEXT'),
        depositConsentText: this.$pgettext('registration', 'DEPOSIT_CONSENT_TEXT [link_text](http://link_url) [link_text2](http://link_url) [link_text3](http://link_url)'),
        errors: {
          bankAccountNumber: this.$pgettext('registration', 'Insert correct bank account'),
          clearingNumber: this.$pgettext('registration', 'Insert correct clearing number'),
          checkboxValidation: this.$pgettext('registration', 'Please select at least one origin for the money')
        },
        submitText: this.$pgettext('registration', 'SUBMIT_FORM_BUTTON_TEXT')
      }
    },
    isOtherMoneyOriginShown () {
      return this.form.moneyOrigins.includes('OTHER')
    },
    moneyOriginOptions () {
      return this.moneyOriginValues.map(({ lookupValueCode }) => ({
        label: this.translations.moneyOrigin[lookupValueCode] || this.toCleanedTitleCase(lookupValueCode),
        name: lookupValueCode
      }))
    }
  },
  created () {
    this.fetchMoneyOriginValues()
    this.$validator.extend('se_bank_account_number', {
      getMessage: () => this.translations.errors.bankAccountNumber,
      validate: value => validateAccount(this.form.clearingNumber, value)
    })
    this.$validator.extend('se_clearing_number', {
      getMessage: () => this.translations.errors.clearingNumber,
      validate: value => {
        if (this.form.bankAccountNumber) {
          this.$validator.validate('bankAccountNumber')
        }
        return validateClearingNumber(value)
      }
    })
    this.isLoading = false
  }
}
</script>
<style lang="scss" scoped>
.money-origin-label {
  font-family: $gotham-medium;
  font-size: $font-size-small;
  color: $gray-90;
}
</style>
