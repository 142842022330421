<script>
import PersonalDetails from './PersonalDetails'
import AdditionalDetails from './AdditionalDetails'
import AddressDetails from './AddressDetails'
import CommonRegistration from '../common/CommonRegistration'
import stepsConfig from '@/views/lt/steps.config'

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  extends: CommonRegistration,
  computed: {
    views () {
      return [
        'selector',
        ...stepsConfig.private
      ]
    }
  }
}
</script>
