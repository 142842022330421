<template>
  <bb-modal
    :visible="visible"
    flavor="card"
    publicSize="m"
    @close="onClose"
    fullMobile
    >
    <p class="signing-error__header">
      {{errorTitle}}
    </p>
    <div v-if="showAlertIcon" class="signing-error__alert-icon">
      <bb-icon size="fluid" fill="original" name="illustration-exclamation-mark"></bb-icon>
    </div>
    <div class="signing-error__error-message">
      <text-with-links
          v-if="isInactiveCertificateError"
          :text="errorMessage" />
      <span v-else>{{errorMessage}}</span>
    </div>
    <div v-if="button" class="signing-error__button custom-hidden-desktop" slot="footer">
      <bb-button v-bind="buttonProps" @click="onClose" display="block" size="lg" class="m-s-a" corner="sharp"></bb-button>
    </div>
    <div v-if="button" class="signing-error__button my-hidden-mobile" slot="footer">
      <bb-button v-bind="buttonProps" @click="onClose"></bb-button>
    </div>
  </bb-modal>
</template>
<script>
import '@bigbank/interface-components/dist/svg/illustration/exclamation-mark'
import { AuthMethod } from '@bigbank/dc-common/config'
import { BbButton, BbIcon, BbModal } from '@bigbank/interface-components'
import TextWithLinks from '@/components/TextWithLinks.vue'
import { ErrorCode } from '@bigbank/dc-common/clients/http/digital-signing-service/digital-signing-service.enums'

export default {
  name: 'signing-error-messages',
  components: {
    TextWithLinks,
    BbModal,
    BbButton,
    BbIcon
  },
  props: {
    code: {
      default: null,
      required: false
    },
    type: {
      default: null,
      required: false
    },
    data: {
      default: null,
      required: false
    },
    titleOverride: {
      type: String,
      default: null,
      required: false
    },
    showAlertIcon: {
      type: Boolean,
      default: true
    },
    button: {
      type: String,
      default: 'close'
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    errorTitle () {
      return this.titleOverride || this.$pgettext('error_bank_id_signing', 'Error')
    },
    errorMessage () {
      if (!this.code) {
        return ''
      }

      const captureMessage = `Signing error code ${this.code}`
      if (window.APM_CONFIG) {
        this.$apm.captureError(new Error(captureMessage))
      } else {
        console.error(captureMessage)
      }

      const smartIdErrors = {
        SIGNATURE_SMART_ID_RESULT_USER_REFUSED: this.$pgettext('smart_id_errors', 'User refused authentication'),
        SK_SIGN_EXPIRED_TRANSACTION: this.$pgettext('smart_id_errors', 'Authentication expired'),
        SMART_ID_API_0: this.$pgettext('smart_id_errors', 'ID not found')
      }

      const eparakstsErrors = {
        INACTIVE_CERTIFICATE: this.$pgettext('eparaksts_errors', 'Certificate is expired or is not yet active. How to create an eSignature password can be found [here](https://www.eparaksts.lv/lv/palidziba/buj/eParaksts_mobile_lietosana/Ka_atjaunot_eParaksts_paroli)')
      }

      const commonErrors = {
        USER_CANCELLED: this.$pgettext('common_errors', 'User cancelled the signing request'),
        TIMEOUT: this.$pgettext('common_errors', 'Signing request timed out'),
        INVALID_PHONE_EE: this.$pgettext('common_errors',
          'Your main mobile number is not a valid Estonian Phone Number.\n' +
            'Please add a valid Estonian phone number to your account contact data'
        ),
        INVALID_ID_CODE: this.$pgettext('common_errors',
          'Your Personal Identity Code not valid.\n' +
            'Please add a valid Estonian ID to your account'
        ),
        TIMED_OUT: this.$pgettext('common_errors', 'Signature timed out'),
        UNKNOWN_SIGN_METHOD: this.$pgettext('common_errors',
          'You cannot sign documents with chosen login method, please log out and' +
            ' log in with a method that can be used for signing documents'
        ),
        EMU_SIGN_METHOD: this.$pgettext('common_errors', 'You cannot sign documents with EMU login method. ' +
          'If you are a developer please override the signing method as specified in Readme.md')
      }

      const defaultError = this.$pgettext('error_bank_id_signing', 'Something appears to have gone wrong. Please try again later.')
      let error

      if (this.type === AuthMethod.SMART_ID) {
        error = smartIdErrors[this.code] || commonErrors[this.code] || defaultError
      }

      if (this.type === AuthMethod.EPARAKSTS) {
        error = eparakstsErrors[this.code] || commonErrors[this.code] || defaultError
      }

      if (this.type === null) {
        error = commonErrors[this.code] || defaultError
      }

      if (this.data) { return this.$gettextInterpolate(error, { errorData: this.data }) } else { return error }
    },
    isInactiveCertificateError () {
      return this.type === AuthMethod.EPARAKSTS && this.code === ErrorCode.InactiveCertificate
    },
    buttonProps () {
      const props = {
        display: 'block'
      }

      switch (this.button) {
        case 'logout':
          props.label = this.$gettext('Logout')
          props.to = '/logout?redirectToLogin'
          break
        case 'close':
        default:
          props.label = this.$gettext('Close')
          break
      }

      return props
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .signing-error {
    &__alert-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 160px;
      width: auto;
    }

    &__error-message {
      margin-top: 25px;
      color: $gray;
      text-align: center;
    }

    &__header {
      color: $navy;
      font-family: $max-pro-demi-bold;
      font-size: $h4-size;
      margin-bottom: 40px;
    }
  }

  .custom-hidden-desktop {
    @media (min-width: $desktop-breaking-point + 1) {
      display: none !important;
    }
  }

  .my-hidden-mobile {
    @media (max-width: $desktop-breaking-point) {
      display: none !important;
    }
  }
</style>
