<template>
  <bb-application-page
      :form-props="{
      uppercaseButtons: false,
      submitText: translations.signConfirmation,
      disabled: isLoading,
      loading: isLoading,
    }"
      @submit="onSubmit"
  >
    <template #header>
      <bb-public-header
          :title=translations.title
          icon="client-identification"
      />
    </template>

    <p class="align-center f-gotham-medium f-small p-t-20">
      {{ translations.legalText }}
    </p>

    <signing-modal
        v-show="showSigningModal"
        ref="signing"
        :initMethod="initSigning"
        @signingSuccessful="complete"
        @signingCancelled="() => {}"
    />
  </bb-application-page>
</template>

<script>
import { BbApplicationPage, BbPublicHeader } from '@bigbank/interface-components'
import api from '@/api'
import SigningModal from '@/views/lv/signing/SigningModal.vue'
import RegistrationMixin from '@/views/common/RegistrationMixin.vue'
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'confirm-registration-data',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    SigningModal
  },
  mixins: [
    RegistrationMixin
  ],
  computed: {
    ...mapState(useAppStore, ['formData']),
    translations () {
      return {
        title: this.$pgettext('LV_application_confirmation', 'Sign contract'),
        legalText: this.$pgettext('LV_application_confirmation', 'Confirm submitted data with a signature to become our customer'),
        signConfirmation: this.$pgettext('LV_application_confirmation', 'Sign contract')
      }
    }
  },
  data () {
    return {
      isLoading: false,
      showSigningModal: false
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep']),
    onSubmit () {
      this.showSigningModal = true
      this.$refs.signing.signButtonClick()
    },
    async initSigning () {
      const initResponse = await api.initConfirmRegistrationData()

      return {
        signingRequestId: initResponse.signingRequestId,
        method: initResponse.method
      }
    },
    async complete () {
      this.isLoading = true
      try {
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted () {
    if (this.$route?.query?.callback) {
      this.showSigningModal = true
      await this.$refs.signing.completeSigning()
    }
  }
}
</script>
