<template>
  <div class="thank-you-wrapper__container">
    <div class="thank-you-wrapper__title">
      <slot name="title" />
    </div>
    <div class="thank-you-wrapper__alert">
      <slot name="alert" />
    </div>
    <div class="thank-you-wrapper__text">
      <slot name="text" />
    </div>
    <div>
      <slot name="poweredBy" />
    </div>
    <div class="thank-you-wrapper__button">
      <slot name="button" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { CUSTOMER_TYPE } from '../const'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'thank-you-wrapper',
  computed: {
    ...mapState(useAppStore, ['status', 'constants', 'registrationId', 'isRegistrationTrackingEventSent'])
  },
  methods: {
    ...mapActions(useAppStore, ['setIsRegistrationTrackingEventSent']),
    isRegistrationCompletedSuccessfully (registrationStatus) {
      return registrationStatus === this.constants.REGISTRATION_STATUSES.COMPLETED
    },
    sendTrackingEvent (customerType) {
      const category = customerType === CUSTOMER_TYPE.Legal ? 'Legal Customer Deposit Onboarding' : 'Onboarding'

      window.dataLayer.push({
        applicationId: this.registrationId
      })
      this.$gtm.trackEvent({
        event: 'registration-completed',
        label: 'Onboarding - Thank you',
        action: 'onboarding-registration-status-update',
        category
      })
      this.setIsRegistrationTrackingEventSent(true)
    }
  },
  watch: {
    status: function (newValue) {
      if (this.$gtm && this.$gtm.enabled() && this.isRegistrationCompletedSuccessfully(newValue.status) && !this.isRegistrationTrackingEventSent) {
        this.sendTrackingEvent(newValue.customerType)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .thank-you-wrapper {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 375px;
    }

    &__separator {
      max-width: 375px;
      width: 100%;
    }

    &__text {
      font-family: $gotham-medium;
      font-size: $smallest-font-size;
      color: $gray-90;
      margin: 0 10px;
      max-width: 375px;
      width: 100%;
    }

    &__button {
      max-width: 375px;
      width: 100%;
      margin-top: 30px;
    }

    &__alert {
      max-width: 375px;
      width: 100%;
    }
  }
</style>
