<template>
  <bb-modal
      :visible="visible"
      @close="$emit('close')"
      flavor="card" publicSize="m"
      fullMobile>
    <div class="login-modal">
      <p class="login-modal__header">
        <span v-if="errorCode">{{ translations.errorHeader }}</span>
        <span v-else>{{ translations.header }}</span>
      </p>
      <div>
        <bb-icon name="client-smart-id" size="64" fill="original"></bb-icon>
        <p class="login-modal__pincode">{{ challengeId }}</p>
        <p class="login-modal__text">{{ translations.modalText }}</p>
        <bb-link class="login-modal__cancel" @click="cancelSignature">{{ translations.cancel }}</bb-link>
      </div>
    </div>
    <bb-button
        v-if="canRetry"
        slot="footer"
        display="block"
        uppercase
        @click="$emit('close')"
        class="m-s-a">{{ translations.tryAgain }}
    </bb-button>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/client/smart-id'
import { BbButton, BbIcon, BbLink, BbModal } from '@bigbank/interface-components'

export default {
  name: 'signature-verification-modal',
  components: {
    BbModal,
    BbButton,
    BbLink,
    BbIcon
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    challengeId: { type: String },
    errorCode: { type: String }
  },
  computed: {
    translations () {
      return {
        header: this.$pgettext('signature_verification', 'Enter your PIN2'),
        errorHeader: this.$pgettext('signature_verification', 'Oops, something happened'),
        modalText: this.$pgettext('signature_verification', 'Enter your PIN2 on your phone, if you are convinced the control code shown on your phone matches the one shown here.'),
        cancel: this.$pgettext('signature_verification', 'Cancel'),
        tryAgain: this.$pgettext('signature_verification', 'Try logging in again')
      }
    },
    canRetry () {
      return this.errorCode && ![
        'SMART_ID_API_0',
        'SMART_ID_API_404',
        'BB_API_5301',
        'SK_301'
      ].includes(this.errorCode)
    }
  },
  methods: {
    cancelSignature () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .login-modal {
    text-align: center;

    &__header {
      color: $navy;
      font-family: $max-pro-demi-bold;
      font-size: $h4-size;
      margin-bottom: 40px;
    }

    &__pincode {
      margin: 30px 0;
      color: $blue;
      font-size: $h3-size;
      font-family: $gotham-bold;
    }

    &__text {
      font-size: $font-size-small;
      color: $gray-70;
      width: 80%;
      margin: 0 auto 120px;

      /* stylelint-disable */
      @media (max-height: 768px) {
        margin-bottom: 30px;
      }
    }

    &__cancel {
      font-size: $font-size-smallest;
      cursor: pointer;
    }
  }
</style>
