<template>
  <div v-if="showSignModal">
    <component v-if="signingComponent" :is="signingComponent" v-bind="signingMethodProps" @signingSuccessful="onSigned" @close="onClose" />
    <signing-error-messages v-else-if="method" v-bind="errorProps" @close="onClose" />
    <signing-modal-wrapper v-else visible showLoader @close="onClose" />
  </div>
</template>

<script>
import { AuthMethod } from '@bigbank/dc-common/config'
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'
import SigningErrorMessages from '@/components/signing/SigningErrorMessages'
import SignWithSmartId from './SignWithSmartId'
import SignWithEparaksts from './SignWithEparaksts'

const METHODS_TO_COMPONENTS = {
  [AuthMethod.SMART_ID]: 'sign-with-smart-id',
  [AuthMethod.EPARAKSTS]: 'sign-with-eparaksts'
}

export default {
  name: 'signing-modal',
  components: {
    SigningModalWrapper,
    SigningErrorMessages,
    SignWithSmartId,
    SignWithEparaksts
  },
  props: {
    initMethod: {
      required: true
    }
  },
  data () {
    return {
      signingRequestId: null,
      chosenSignMethod: null,
      showSignModal: false,
      initResult: null,
      translations: {
        useAnotherMethod: this.$pgettext('signing_modal', 'Please use another method')
      }
    }
  },
  computed: {
    method () {
      return this.chosenSignMethod
    },
    signingComponent () {
      return this.method && METHODS_TO_COMPONENTS[this.method]
    },
    signingMethodProps () {
      return {
        signingRequestId: this.signingRequestId
      }
    },
    errorProps () {
      return {
        code: 'UNKNOWN_SIGN_METHOD',
        button: 'logout',
        titleOverride: this.translations.useAnotherMethod,
        showAlertIcon: true,
        visible: true
      }
    }
  },
  methods: {
    async signButtonClick () {
      this.reset()
      this.showSignModal = true
      this.initResult = await this.initMethod()
      this.signingRequestId = this.initResult?.signingRequestId
      this.chosenSignMethod = this.initResult?.method
      const { isSigningCompleted, hasCVVCheckFailed } = this.initResult

      if (isSigningCompleted) {
        this.showSignModal = false
        this.$emit('signingSuccessful', { hasCVVCheckFailed })
      }

      return false
    },
    async completeSigning () {
      this.signingRequestId = this.$route.query.signingRequestId
      this.chosenSignMethod = this.$route.query.signingMethod
      this.showSignModal = true
    },
    onSigned () {
      this.reset()
      this.$emit('signingSuccessful')
    },
    onClose () {
      this.reset()
      this.$emit('signingCancelled')
      this.$router.replace({
        query: {
          signingRequestId: undefined,
          chosenSignMethod: undefined,
          callback: undefined
        }
      })
    },
    reset () {
      this.showSignModal = false
      this.initResult = null
      this.signingRequestId = null
      this.chosenSignMethod = null
    }
  }
}
</script>
