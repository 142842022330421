<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalDetails'
import AdditionalDetails from './AdditionalDetails'
import DepositDetails from './DepositDetails'
import { mapState } from 'pinia'
import CommonRegistration from '../common/CommonRegistration'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/se/steps.config'

export default {
  extends: CommonRegistration,
  components: {
    PersonalDetails,
    AdditionalDetails,
    DepositDetails
  },
  computed: {
    ...mapState(useAppStore, ['currentStep']),
    views () {
      return stepsConfig.private
    }
  }
}
</script>
