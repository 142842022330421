<template>
  <additional-details
      :field-definitions="fieldDefinitions"
      :date-format="dateFormat"
      :channel="channel"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia'
import AdditionalDetails from '@/views/common/AdditionalDetails'
import dayjs from 'dayjs'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'additional-details-cb',
  components: {
    AdditionalDetails
  },
  data () {
    return {
      isLoading: true,
      taxResidency: undefined,
      fieldDefinitions: [
        { name: 'fieldOfActivity' },
        { name: 'customFieldOfActivity' },
        {
          name: 'dateOfBirth',
          validator: {
            validate: value => {
              const date = dayjs(value, this.dateFormat, true)
              const isAtLeast18 = dayjs().diff(date, 'year') >= 18

              return date.isValid() && isAtLeast18
            }
          }
        },
        { name: 'placeOfBirth' },
        { name: 'taxResidency' },
        { name: 'taxIdNumber' },
        { name: 'citizenship' },
        { name: 'dualCitizenship' },
        { name: 'isPep' },
        {
          name: 'isPepReason',
          showField: form => {
            return form.isPep === 'YES'
          }
        },
        { name: 'formConfirmationText' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'formData']),
    dateFormat () {
      return this.channel.toUpperCase() === CountryChannel.NL ? ['DD-MM-YYYY', 'DD.MM.YYYY'] : 'DD.MM.YYYY'
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
