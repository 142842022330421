<template>
  <div>
    <div v-if="hasTitle" class="title">{{ title }}</div>
    <div v-for="item in items" :key="item.key" class="steps">
      <div class="steps-item">
        <div class="steps-seq">{{item.key}}.</div>
        <div>{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'thank-you-steps',
  props: {
    title: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasTitle () {
      return this.title && this.title.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-family: $gotham-medium;
    font-size: $default-font-size;
    color: $navy;
    margin-left: 15px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    font-size: $small-font-size;
    margin-left: 15px;

    &-item {
      display: flex;
      flex-direction: row;
      font-family: $gotham-book;
      color: $gray;
      margin-top: 18px;
    }

    &-seq {
      font-family: $gotham-bold;
      color: $green;
      margin-right: 10px;
    }
  }
</style>
