<template>
  <common-layout v-bind="props">
    <router-view />
  </common-layout>
</template>

<script>
import { mapState } from 'pinia'
import RoutingMixin from '../../common/RoutingMixin'
import CommonLayout from '../../../components/CommonLayout'
import { CUSTOMER_TYPE } from '../../../const'
import { useAppStore } from '@/store/app.store'

export default {
  mixins: [
    RoutingMixin
  ],
  components: {
    CommonLayout
  },
  computed: {
    ...mapState(useAppStore, ['currentStep', 'customerType', 'status', 'isV2Flow', 'isV3Flow']),
    hideLoginButton () {
      return this.customerType === CUSTOMER_TYPE.Legal
    },
    props () {
      const props = {}
      const progressBar = {
        max: 4,
        value: this.currentStep + 1,
        color: 'blue'
      }

      if ((this.isV2Flow || this.isV3Flow) && this.customerType === CUSTOMER_TYPE.Private) {
        props.hideLoginButton = true
        if (this.status.status) {
          props.progressBar = progressBar
        }
      } else {
        props.hideLoginButton = this.hideLoginButton
        props.progressBar = progressBar
      }

      return props
    }
  }
}
</script>
