<script>
import PersonalDetails from './PersonalDetails'
import AdditionalDetails from './AdditionalDetails'
import AddressDetails from './AddressDetails'
import CommonRegistration from '../../common/CommonRegistration'
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/ee/steps.config'

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  extends: CommonRegistration,
  computed: {
    ...mapState(useAppStore, ['isV2Flow']),
    views () {
      if (this.isV2Flow) {
        return [
          'selector',
          ...stepsConfig.private
        ]
      } else {
        return stepsConfig.private
      }
    }
  }
}
</script>
