<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalDetails'
import AdditionalDetails from './AdditionalDetails'
import RegistrationAddressDetails from './RegistrationAddressDetails'
import CommonRegistration from '../common/CommonRegistration'
import stepsConfig from '@/views/cb/steps.config'

export default {
  computed: {
    views () {
      return stepsConfig.private
    }
  },
  components: {
    PersonalDetails,
    AdditionalDetails,
    RegistrationAddressDetails
  },
  extends: CommonRegistration
}
</script>
