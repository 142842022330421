<template>
  <div class="checklist">
    <div v-if="hasTitle" class="checklist-title">{{ title }}</div>
    <bb-list-group class="m-t-10 m-b-20">
      <bb-list-item v-for="item in items" :key="item.key" condensed>
        <div class="checklist-item">{{item.value}}
          <span v-for="link in item.links" :key="link.key">
            <a v-if="link.url" class="checklist-item-link" :href="link.url">{{link.text}}</a>
            <span v-else class="checklist-item"> {{link.text}} </span>
          </span>
        </div>
      </bb-list-item>
    </bb-list-group>
  </div>
</template>

<script>
import { BbListGroup, BbListItem } from '@bigbank/interface-components'

export default {
  name: 'thank-you-checklist',
  props: {
    title: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  components: {
    BbListGroup,
    BbListItem
  },
  computed: {
    hasTitle () {
      return this.title && this.title.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .checklist {
    &-title {
      font-family: $gotham-medium;
      font-size: $small-font-size;
      color: $gray-80;
    }

    &-item {
      font-family: $gotham-medium;
      font-size: $smallest-font-size;
      color: $gray;

      &-link {
        font-family: $gotham-medium;
        font-size: $smallest-font-size;
        color: $green;
      }
    }
  }
</style>
